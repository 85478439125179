/* TIMELY Calendar Widget */
.timely {
	font-family: $brand-font2;
}
.ai1ec-calendar-toolbar { border-style: dashed; display: none; }
.ai1ec-title-buttons .ai1ec-btn-group { margin-left: 0; }
.timely a.ai1ec-btn {
	border-radius: 0;
	color: #fff;
	text-align: center !important;
	background: $brand-tertiary;
	border: none;
	transition: $global-transition;
}
.timely a.ai1ec-btn:hover {
	color: #fff;
	background: $brand-primary;
}
.ai1ec-btn,
.ai1ec-btn-xs {
    padding: 6px 12px!important;
    font-size: 14px!important;
    line-height: 1.42857143!important;
}
.ai1ec-views-dropdown { margin-top: 5px; }
.ai1ec-views-dropdown i { font-size: 1.4em; line-height: 11px; }
.ai1ec-dropdown.ai1ec-btn { background: $brand-tertiary; border: none; color: #fff; border-radius: 0; }
.ai1ec-dropdown.ai1ec-btn:hover { background: $brand-primary; color: #fff; }
.ai1ec-dropdown.ai1ec-btn .ai1ec-dropdown-toggle { padding: 0; }
.ai1ec-dropdown-menu a,
.ai1ec-dropdown-menu > div > a,
.ai1ec-dropdown-menu > div > a:visited { color: $brand-tertiary; padding: 8px 25px 8px 15px; }
.ai1ec-dropdown-menu > div.ai1ec-active > a { background: $brand-secondary; color: #fff; }


.ai1ec-agenda-widget-view .ai1ec-date { 
	background: $brand-primary; 
	margin: 0; 
	min-height: 57px; 
	border-radius: 0 0 15px 0;
	font-family: $brand-font2;
}

.ai1ec-agenda-view .ai1ec-date-events { 
	margin: 0; 
	border: 1px solid #e5e5e5;
	background: #fff;
}
.ai1ec-agenda-view .ai1ec-date-events:hover .ai1ec-event-title { color: $brand-primary; }
.ai1ec-agenda-widget-view .ai1ec-date-events { 
	margin: 0; 
	padding-left: 4.7em;
}
.ai1ec-agenda-view .ai1ec-date,
.ai1ec-agenda-widget-view .ai1ec-date {
	margin: 0 0 10px;
	background: none;
	border: none;
}
.ai1ec-agenda-view .ai1ec-date { transition: $global-transition; }
.ai1ec-agenda-view .ai1ec-date:hover {
	background: $brand-secondary;
	.ai1ec-date-title { background: $brand-secondary; }
}
.ai1ec-agenda-widget-view .ai1ec-date-title,
.ai1ec-agenda-view .ai1ec-date-title {
	margin: 6.5px .75em 6.5px 0;
}
.ai1ec-agenda-view .ai1ec-event .ai1ec-event-title {
	color: $brand-primary;
	font-size: 1.3em;
}
.ai1ec-agenda-widget-view .ai1ec-event .ai1ec-event-title {
	font-size: 1.2em;
}
.ai1ec-agenda-widget-view .ai1ec-month,
.ai1ec-agenda-view .ai1ec-month {
	background: $brand-primary;
	color: #fff;
	text-shadow: none;
	letter-spacing: 0em;
	padding: .3em 0 .25em;
	border-radius: 0;
	font-size: 1em;
	font-family: $brand-font2;
}
.ai1ec-agenda-widget-view .ai1ec-weekday,
.ai1ec-agenda-view .ai1ec-weekday {
	display: none;
}
.ai1ec-agenda-widget-view .ai1ec-day,
.ai1ec-agenda-view .ai1ec-day { 
	padding: .5em;
	font-weight: normal;
	font-size: 1.6em;
}
.ai1ec-agenda-widget-view .ai1ec-date-title,
.ai1ec-agenda-view .ai1ec-date-title {
	border: none;
	width: 4.3em;
	background: $brand-tertiary;
	color: #fff;
	text-shadow: none;
	padding: 0;
	margin: 0;
	border-radius: 0;
}


.ai1ec-agenda-widget-view .ai1ec-event > a:hover { border: none !important; }
.ai1ec-agenda-widget-view .ai1ec-date-title { float: left; margin-right: 10px; position: relative; z-index: 1; }
.ai1ec-agenda-widget-view .ai1ec-date-title:hover,
.ai1ec-agenda-view .ai1ec-date-title:hover { background: $brand-secondary; }
.ai1ec-agenda-widget-view a.ai1ec-date-title,
.ai1ec-agenda-view a.ai1ec-date-title,
.ai1ec-agenda-widget-view a.ai1ec-date-title:hover,
.ai1ec-agenda-view a.ai1ec-date-title:hover { color: #fff !important; }
.ai1ec-agenda-view .ai1ec-event, .ai1ec-agenda-widget-view .ai1ec-event { 
	margin: 0;
	border-radius: 0;
	border: none;
}
.ai1ec-agenda-view .ai1ec-date { background: $brand-tertiary; }
.ai1ec-agenda-view .ai1ec-event { background: none; padding: .8em; }
.ai1ec-agenda-view .ai1ec-event.ai1ec-expanded { border-color: #e5e5e5; }
.ai1ec-agenda-widget-view .ai1ec-event > a {
   background: none;
   border: none;
   color: $brand-primary;
   padding: .3em;
}
.ai1ec-agenda-widget-view .ai1ec-event > a:hover {
	color: $brand-secondary;
	border: 1px solid rgba(255,255,255,.6);
}
.page-content .ai1ec-agenda-widget-view .ai1ec-event > a,
.page-content .ai1ec-popover.popup {
	background-color: #fff;
}
.ai1ec-btn-group a:visited {
    color: #f6f56a;
}
.ai1ec-agenda-widget-view .ai1ec-allday-badge {
	display: table;
}
.ai1ec-allday-badge { 
	background: $brand-tertiary; 
	padding: .19em .8em 0.1em;
	text-transform: uppercase;
	font-size: .8em;
}
.timely a { color: $brand-primary; }

.ai1ec-agenda-widget-view .ai1ec-date.ai1ec-today { 
	border-top: 0;
	background: none;
	.ai1ec-date-title {
		background: $brand-tertiary;
	}	
} 
.ai1ec-agenda-widget-view .ai1ec-date.ai1ec-today a:hover { color: $brand-primary; }
.ai1ec-agenda-view .ai1ec-date.ai1ec-today { 
	border-top: 0;
	background: $brand-tertiary;
}
.ai1ec-agenda-view .ai1ec-today {
	.ai1ec-date { background: $brand-tertiary; }
	.ai1ec-date-title { background: $brand-tertiary; }
	.ai1ec-date-events { }
}

.base-column-content .timely.ai1ec-agenda-widget-view .ai1ec-subscribe-dropdown { min-height: auto; }
.base-column-content .timely.ai1ec-agenda-widget-view > p { margin: 0; }


/* TIMELY Calendar Page */
.ai1ec_event {
	time.updated, .byline {
    	display: inline-block;
    	color: #999;
    	a { color: #999; }
	}
	.byline:before { content: " - "; }
	.timely.ai1ec-single-event { 
    	margin-top: 3em;
		.ai1ec-field-label { width: auto; }
		.ai1ec-btn { color: #fff !important; }
		.ai1ec-btn:hover {
			color: #fff !important; 
			background: $brand-primary;
		}
	}
}