/* Search form */
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


/* Common form controls
// Shared size and type resets for form controls. Apply `.form-control` to any
// of the following form controls:
*/
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  display: block;
  width: 100%;
  min-height: 1.5em; /* height: $input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border) */
  padding: .3em .5em; /* padding: $padding-base-vertical $padding-base-horizontal; */
  font-size: 1em; /* $font-size-base; */
  line-height: $line-height-base;
  color: $input-color;
  background-color: $input-bg;
  background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
  border: 1px solid $input-border;
  border-radius: 0; /* border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS. */
  @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

  /* Customize the :focus state to imitate native WebKit styles. */
  @include form-control-focus();

  /* Placeholder */
  @include placeholder;

  /* Disabled and read-only inputs */
  /* HTML5 says that controls under a fieldset > legend:first-child won't be disabled if the fieldset is disabled. Due to implementation difficulty, we don't honor that edge case; we style them as disabled anyway. */
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    background-color: $input-bg-disabled;
    opacity: 1; /* iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655 */
  }

  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }

  /* [converter] extracted textarea& to textarea.form-control */
}

/* Reset height for `textarea`s */
textarea {
  height: auto;
}


.btn, 
.comment-form input[type="submit"],
.comment-form input[type="text"], 
.comment-form input[type="email"],
.comment-form input[type="url"], 
.comment-form textarea, 
.form-control { padding-top: 8px; padding-bottom: 8px; height: auto; }

.form-control:focus {
 border-color: $brand-tertiary;
 outline: 0;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($brand-tertiary, .6);
}