body {
	background: $body-background;
	color: $body-color;
 font-family: $body-font;
 font-size: 1.6em;
}
.content-wrap { font-size: 1.1em; }

/* HEADINGS */
h1, .h1, h2, .h2, h3, .h3 {
	color: $brand-primary;
	font-family: $brand-font; 
	font-weight: normal;
	margin: 0 0 .3em;
}
h4, .h4, h5, .h5, h6, .h6 { margin: .4em 0; color: $brand-tertiary; font-family: $brand-font2; }
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {	@include anti-alias; }

h1 { font-size: 2.2em; }
h2 { font-size: 2em; }
h3 { font-size: 1.8em; }
h4 { font-size: 1.6em; }
h5 { font-size: 1.4em; }
h6 { font-size: 1.2em; }

.underlined {
	margin: 0 0 .5em;
	font-weight: 200;
	display: table;
	color: $brand-primary;
	a { color: $brand-primary; &:hover { color: $brand-secondary; text-decoration: none; } }
	&:after {
	 display: block;
		border: 1px solid $brand-secondary;
		width: 100%;
		margin-top: .2em;
	 content: "";
	}
}
/* ---- */


/* LINKS */
a { 
 color: $brand-tertiary; 
 border-bottom: none;
 text-decoration: none; 
 &:hover {
  color: lighten($brand-primary,15%);
   text-decoration: none; 
 }
 &:visited { color: darken($brand-tertiary, 10%); }
}
a, a:link, a:visited, a:hover, a:focus { transition: $global-transition; }

.content-wrap a { text-decoration: underline; }
.content-inner { 
	a { 
		display: inline-block;
		margin-bottom: .3em;
		border-bottom: 3px solid rgba($brand-tertiary,.3);
		text-decoration: none;
		&:hover { border-color: $brand-tertiary; }
	}
	/*
	a.btn.btn-default { 
  color: $brand-tertiary; 
  &:hover { color: #fff; } 
 }
 .btn-primary, .btn-secondary, .btn-tertiary { color: #fff !important; }
	*/
}
/* ---- */


/* LEADS */
p.lead { 
	font-family: $brand-font;
	color: $brand-primary;
	text-align: center;
	font-style: italic;
	font-size: 1.4em; 
	font-weight: 400;
	@include anti-alias;
}
@media (max-width: $screen-sm-max) {
	p.lead { font-size: 1.2em; }
}
@media (max-width: $screen-xs-max) {
	p.lead { font-size: 1em; }
}
/* ---- */


/* IMAGES */
main img { max-width: 100%; height: auto; }
table img { width: 100%; }

img.img-card {
	margin: 1em 0;
	box-shadow: 0 0 2em rgba(0,0,0,.2);
}
/* ---- */


/* WELLS */
.well { border-radius: 1em; }
.well.well-quote {
  font-family: $brand-font2; 
  border-radius: 0;
  margin-bottom: 1em;
		font-size: 1.2em;
}
.well.well-primary { 
  background: $brand-primary;
		border-color: $brand-primary;
  color: #fff;
		h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-secondary { 
  background: $brand-secondary;
		border-color: $brand-secondary;
  color: #fff;
		h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-tertiary { 
  background: $brand-tertiary;
		border-color: $brand-tertiary;
  color: #fff;
		h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-quaternary { 
  background: $brand-quaternary;
		border-color: $brand-quaternary;
  color: #fff;
		h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 { color: #fff; }
}
.well.well-quote.well-lg {
  padding: .7em 1em; 
  font-size: 32px;
}
.well.well-quote.well-sm {
  padding: .5em .8em; 
  font-size: 24px;
}
.well.well-quote.well-left {
  text-align: right;
}
.well.well-quote.well-right {
  text-align: right;
}
@media (max-width: $screen-xs-max) {
 .well.well-quote.well-lg {
  font-size: 26px;
 }
 .well.well-quote.well-sm {
  font-size: 18px;
 }
}
/* ---- */


/* MISC */
.clear { clear: both; }

.float-right { float: right; }
.float-left { float: left; }
.float-none { float: none; }

.width-auto { width: auto; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-sm { font-size: .8em; }
.text-md { font-size: 1.2em; }
.text-lg { font-size: 1.4em; }

.caps { text-transform: uppercase; }

.primary { color: $brand-primary !important; }
.secondary { color: $brand-secondary !important; }
.tertiary { color: $brand-tertiary !important; }
.quaternary { color: $brand-quaternary !important; }

.primary-bg { background-color: $brand-primary !important; }
.secondary-bg { background-color: $brand-secondary !important; }
.tertiary-bg { background-color: $brand-tertiary !important; }
.quaternary-bg { background-color: $brand-quaternary !important; }

li.draft { display: none; }

hr {
  margin: 6% 0;
  border: 0 none;
  height: 3px; 
  overflow: hidden;
  background: url('../images/hr.png') repeat-x 50% 50%;
}
hr.accent {
  margin: 6% 0;
  border: 0 none;
  height: 41px; 
  overflow: hidden;
  background: url('../images/hr-accent.png') repeat-x 50% 50%;
}

ul { list-style-type: square; }
ul.list-style-none { padding: 0; li { list-style-type: none; } }

.pad-top { padding-top: .5em; }
.pad-bottom { padding-bottom: .5em; }

.margin-top { margin-top: .5em; }
.margin-bottom { margin-bottom: .5em; }
.margin-center { margin-right: auto; margin-left: auto; }

.desaturate { @include desaturate; }
.anti-alias { @include anti-alias; 	}
/* ---- */


/* ICONS */
.icon-round, .glyphicon.round { 
  height: 2em;
  width: 2em;
  border-radius: 10em;
  padding: 0.5em 0;
  text-align: center;
  display: inline-block; 
  margin-right: .5em;
}
.icon-rounded, .glyphicon.rounded { 
  height: 2em;
  width: 2em;
  border-radius: .3em;
  padding: 0.55em .5em .5em .6em;
  text-align: center;
  display: inline-block; 
  margin-right: .5em;
}
.icon-square, .glyphicon.square {
  height: 2em;
  width: 2em;
  padding: 0.5em 0;
  text-align: center;
  display: inline-block; 
  margin-right: .5em;
}
.icon-xs, .glyphicon.xs { 
	font-size: .6em; 
	line-height: 1em;
}
.icon-sm, .glyphicon.sm { 
	font-size: 1.2em; 
	line-height: 1.2em;
}
.icon-md, .glyphicon.md { 
	font-size: 1.4em; 
	line-height: 1.4em;
}
.icon-lg, .glyphicon.lg { 
	font-size: 1.6em;
	line-height: 1.6em;
}
.icon-xl, .glyphicon.xl { 
	font-size: 1.8em;
	line-height: 1.8em;
}

.icon-primary, .glyphicon.primary { color: $brand-primary; } 
a:hover .icon-primary, a:hover .glyphicon.primary { color: $brand-secondary; }
.icon-secondary, .glyphicon.secondary { color: $brand-secondary; } 
a:hover .icon-secondary, a:hover .glyphicon.secondary { color: #fff; }
.icon-tertiary, .glyphicon.tertiary { color: $brand-tertiary; } 
a:hover .icon-tertiary, a:hover .glyphicon.tertiary { color: $brand-secondary; }
.icon-white, .glyphicon.white { color: $brand-primary; background: #fff; } 
a:hover .icon-white, a:hover .glyphicon.white { color: $brand-secondary; }

.icon-primary.icon-round, 
.icon-primary.icon-rounded,
.icon-primary.icon-square,
.glyphicon.primary.round,
.glyphicon.primary.rounded,
.glyphicon.primary.square { background: $brand-primary; color: #fff; }
.icon-secondary.icon-round, 
.icon-secondary.icon-rounded,
.icon-secondary.icon-square,
.glyphicon.secondary.round,
.glyphicon.secondary.rounded,
.glyphicon.secondary.square { background: $brand-secondary; color: #fff; }
.icon-tertiary.icon-round, 
.icon-tertiary.icon-rounded,
.icon-tertiary.icon-square,
.glyphicon.tertiary.round,
.glyphicon.tertiary.rounded,
.glyphicon.tertiary.square { background: $brand-tertiary; color: #fff; }

.-indentspan span { float: left; display: inline-block; text-indent: -9999em; display: none; }

/* SOCIAL COLORS */
$twitter: #00aced; 
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$wordpress: #21759b;
$stumbleupon: #eb4823;
$snapchat: #fffc00;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;
$spotify: #00e461;
$whatsapp: #4dc247;
$rss: #ff6600;

@mixin social-icon($social) {
	color: $social; &:hover, &:focus { color: lighten($social,10%) !important; }
}

@mixin social-icon-bg($social) {
	color: #fff; background-color: $social; &:hover, &:focus { color: #fff !important; background-color: lighten($social,10%) !important; }
}

.twitter { @include social-icon($twitter); }
.facebook { @include social-icon($facebook); }
.googleplus { @include social-icon($googleplus); }
.pinterest { @include social-icon($pinterest); }
.linkedin { @include social-icon($linkedin); }
.youtube { @include social-icon($youtube); }
.vimeo { @include social-icon($vimeo); }
.tumblr { @include social-icon($tumblr); }
.instagram { @include social-icon($instagram); }
.flickr { @include social-icon($flickr); }
.dribble { @include social-icon($dribble); }
.foursquare { @include social-icon($foursquare); }
.wordpress { @include social-icon($wordpress); }
.snapchat { @include social-icon($snapchat); }
.stumpleupon { @include social-icon($stumbleupon); }
.yahoo { @include social-icon($yahoo); }
.blogger { @include social-icon($blogger); }
.soundcloud { @include social-icon($soundcloud); }
.whatsapp { @include social-icon($whatsapp); }
.rss { @include social-icon($rss); }

.social-icons-bg {
	.twitter { @include social-icon-bg($twitter); }
.facebook { @include social-icon-bg($facebook); }
.googleplus { @include social-icon-bg($googleplus); }
.pinterest { @include social-icon-bg($pinterest); }
.linkedin { @include social-icon-bg($linkedin); }
.youtube { @include social-icon-bg($youtube); }
.vimeo { @include social-icon-bg($vimeo); }
.tumblr { @include social-icon-bg($tumblr); }
.instagram { @include social-icon-bg($instagram); }
.flickr { @include social-icon-bg($flickr); }
.dribble { @include social-icon-bg($dribble); }
.foursquare { @include social-icon-bg($foursquare); }
.wordpress { @include social-icon-bg($wordpress); }
.snapchat { @include social-icon-bg($snapchat); }
.stumpleupon { @include social-icon-bg($stumbleupon); }
.yahoo { @include social-icon-bg($yahoo); }
.blogger { @include social-icon-bg($blogger); }
.soundcloud { @include social-icon-bg($soundcloud); }
.whatsapp { @include social-icon-bg($whatsapp); }
.rss { @include social-icon-bg($rss); }
}
.social-icons span { padding-left: .25em; }
/* ---- */


/* TABLES */
table thead, table.thead > tbody > tr:first-child { background: $brand-primary; color: #fff; font-size: 1.1em; }
.table-striped > tbody > tr:nth-of-type(2n+1) { background: rgba( $brand-primary, .05 ); }
.table-hover > tbody > tr:hover { background: rgba( $brand-primary, .1 ); }
.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th,
.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th { border-color: rgba( $brand-primary, .1 ); }

table.lg-padd td { padding: 22px !important; }
table.md-padd td { padding: 11px !important; }

@-moz-document url-prefix() {
 fieldset { display: table-cell; }
}
/* ---- */


/* MODALS */
$modal-backdrop: $brand-primary;
.modal { 
	top: 5vh;
	button.close {
		opacity: 1;
		background: #444;
		border: 2px solid #fff;
		border-radius: 999em;
		box-shadow: 0 0 5px rgba(0,0,0,.3);
		color: #fff;
		font-size: 2em;
		line-height: .6em;
		height: 1.2em;
		width: 1.2em;
		position: absolute;
		top: -.35em;
		right: -.35em;
		&:hover, &:focus {
			opacity: 1;
			background: #fff;
			color: #444;
			border-color: #444;
		}
	}
	.modal-body { padding: 30px; }
}
.modal-backdrop { background: $modal-backdrop; }
.modal-backdrop.in { opacity: .9; }

/* Invisible Modal Box */
.modal-glass {
	.modal-dialog {
		color: #fff;
		width: 100%;
		max-width: 1000px;
		font-size: 1.4em;
	}
	.modal-content {
		background: none;
		box-shadow: none;
		border: none;
	}
	.modal-header {
		border: none;
		/* border-bottom: 2px dashed rgba(255,255,255,.35); */
	}
	.modal-title {
		font-size: 2em;
		font-style: italic;
		text-align: center;
		color: #fff;
	}
	button.close {
		background: $modal-backdrop;
		font-size: 1.75em;
	}
	
	/* Gravity Form styles */
	.gform_wrapper {
		.gfield_required { color: #fff; }
	}
	
	.search-form {
		position: relative;
		> label, input.search-field { width: 100%; height: 44px; font-size: 1em; }
		.search-submit { margin: 0; padding-top: 7px; padding-bottom: 6px; position: absolute; right: 0; top: 0; }
	}
	
}

.modal-video {
	.modal-dialog {
		max-width: 1600px;
	}
}
/* ---- */


/* SPINNER */
.page-loader { 
	width: 100%;
	position: fixed;
	top: 48%;
	z-index: 0; 
}
.sk-fading-circle {
  margin: 0 auto;
  width: 80px;
  height: 80px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $brand-primary;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}
/* ---- */


/* IMAGE ANIMATIONS */
@-webkit-keyframes zoomimagein {
	0% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1.2,1.2); 
		filter: blur(15px);
		-webkit-filter: blur(15px);
	}
	100% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1,1); 
		filter: blur(0);
		-webkit-filter: blur(0);
	}
}
@keyframes zoomimagein {
	0% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1.2,1.2); 
		filter: blur(15px);
		-webkit-filter: blur(15px);
	}
	100% { 
		transform: scale(1.2,1.2); 
		-webkit-transform: scale(1,1); 
		filter: blur(0);
		-webkit-filter: blur(0);
	}
}
@mixin image-animated {
	transition: $global-transition;
	-webkit-animation: zoomimagein 5s;
 animation: zoomimagein 5s;
}
@media (min-width: $screen-md-min) {
	.image-animated {
		@include image-animated;
	}
}
/* ---- */


/* MEDIA QUERIES */
@media only screen and (min-width: $screen-xl-min) {
	body { font-size: 160%; }
	.container { width: $screen-xl-container; max-width: 1500px; }
	/*
	h1 { font-size: 2em; }
	h2 { font-size: 1.8em; }
	h3 { font-size: 1.6em; }
	h4 { font-size: 1.4em; }
	h5 { font-size: 1.2em; }
	h6 { font-size: 1em; }
	*/
}
@media (max-width: $screen-md-min) {

}
@media (max-width: $screen-lg-min) {

}
/* ---- */