a, a.btn, .btn, .icon, button { transition: $global-transition; }
a:hover, .btn:hover, .icon:hover, button { transition: $global-transition; }

a.btn, .btn, a.button, .button, .nav-links a, .wysija-submit, input[type=submit] { 
	transition: $global-transition;
	display: inline-block;
	margin-bottom: 0; // For input.btn
	font-weight: $btn-font-weight;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214 */
	border: 2px solid transparent;
	white-space: wrap;
	@include button-size($padding-base-vertical, $padding-base-horizontal, $font-size-base, $line-height-base, $border-radius-base); 
	@include user-select(none);

	margin-bottom: 1em; 
	padding-left: 1.2em; 
	padding-right: 1.2em; 
	border-radius: 0;
	border-weght: 2px;
	border-color: $brand-tertiary;
	background: $brand-tertiary;
	font-family: $brand-font2;
	font-weight: 700;
	font-size: .9em;
	color: #fff;
	text-transform: uppercase;
	text-decoration: none;
	@include anti-alias;

	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $brand-tertiary;
    	background: none;
    	border-color: $brand-tertiary;
    	text-decoration: none;
  	}

  	&:active,
  	&.active {
    	outline: 0;
    	background-image: none;
    	@include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  	}

  	&.disabled,
  	&[disabled],
  	fieldset[disabled] & {
   		cursor: $cursor-disabled;
   		pointer-events: none; // Future-proof disabling of clicks
    	@include opacity(.65);
    	@include box-shadow(none);
  	}
}

/* Default Button */
a.btn.btn-default { 
	background: none; 
	color: $brand-tertiary; 
	border-color: $brand-tertiary;
	text-decoration: none; 
	&:hover { color: #fff; background: $brand-tertiary; }
}

/* Primary Button */
a.btn-primary {
	background-color: $brand-primary;
	border-color: $brand-primary;
	color: #fff;
}
a.btn-primary:hover, .wysija-submit:hover {
	background-color: none;
	border-color: $brand-primary;
	color: $brand-primary;
}

/* Secondary Button */
a.btn-secondary {
	color: #fff;
	background-color: $brand-secondary;
	border-color: $brand-secondary;
	&:hover { background-color: lighten( $brand-secondary, 10% ); border-color: darken( $brand-secondary, 10% ); color: #fff; }
}

/* Tertiary Button */
a.btn-tertiary, .nav-links a {
	color: #fff !important;
	text-decoration: none !important;
	background-color: $brand-tertiary;
	border-color:$brand-tertiary;
	&:hover { background-color: darken( $brand-tertiary, 10% ); border-color: darken( $brand-tertiary, 10% ); color: #fff; }
}

/* Quaternary Button */
a.btn-quaternary {
	color: #fff !important;
	text-decoration: none !important;
	background-color: $brand-quaternary;
	border-color:$brand-quaternary;
	&:hover { background-color: darken( $brand-quaternary, 10% ); border-color: darken( $brand-quaternary, 10% );; color: #fff; }
}
a.btn-primary:visited, a.btn-secondary:visited, a.btn-tertiary:visited, a.btn-quaternary:visited, a.button:visited, .button:visited { color: #fff; }

/* White Button */
a.btn.btn-white {
	color: #fff;
	border-color: #fff;
	background: none;
	&:hover{
		background: #fff;
		color: $brand-primary;
		border-color: #fff;
	}
}
/* -- */


/* Misc */
a.btn-table { display: table; }

a.btn-lg { font-size: 1.4em; }
a.btn-md { font-size: 1.2em; }

a.btn .fa,
a.btn .glyphicon-fa { 
	padding: 0 .2em; 
	font-size: 1.3em;
}

.btn .glyphicon, .tab-content .glyphicon { padding-right: 8px; }
/* -- */


/* Page Numbers */
.page-numbers.current { 
	padding: .6em 1.2em;
	border: 1px solid transparent;
	margin: 0 0 1em;
	display: inline-block;
	line-height: 1.428571429;
	color: #fff;
	vertical-align: middle;
	background: #999;
	font-size: 1em;
 }
.page-numbers.dots { color: #999; }

/* Post Navigation Links */
.nav-links .nav-previous, .nav-links .nav-next { display: inline-block; margin-right: 15px; }

/* Page Tabs / Nav Tabs */
.nav-tabs { 
	margin-top: 1.5em; 
	> li > a { 
		border-color: transparent; 
		border-bottom: none; 
		text-decoration: none !important;
		font-family: $brand-font2;
		margin: 0;
	} 
  	> li > a:hover, .nav-tabs > li > a:focus { 
		color: #fff;
		background-color: rgba($brand-tertiary, .5)
	}
	> li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    	box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.08);
		border-bottom: none;
	}
}
.nav-tabs + .tab-content {
	padding-left: 30px;
	padding-right: 30px;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
	background: #fff;
}
/* -- */


/* Document Tabs / Nav Pills+Nav Tabs */
.nav-tabs.nav-pills { 
	border-bottom: 1px solid rgba($brand-primary, .5); 
	> li { background: #fff; }
	> li > a { 
		color: $brand-primary; 
		border-radius: 4px 4px 0 0; 
		border: 1px solid rgba($brand-primary,.2); 
		background: rgba($brand-primary,.1); 
		text-decoration: none !important;
		font-family: $brand-font2;
	}
	> li.active a { color: #fff; background: $brand-primary; }
	~.tab-content { border: 1px solid rgba($brand-primary,.5); border-top: none; box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.08); }
	~.tab-content table { margin: 0; }
}
.tab-content {
	.glyphicon { color: rgba($brand-primary,.7); }
	a:hover { color: $brand-secondary; }
}
/* -- */


/* Footer Nav Pills */
.home-documents .nav-pills { 
	a { border: 1px solid rgba(0, 0, 0, 0.3); border-left: none; border-bottom: none; border-radius: 4px 4px 0 0; background: rgba(0,0,0,.1); }
	li:first-child a { border-left: 1px solid rgba(0, 0, 0, 0.3); }
	li.active a { background: rgba(255,255,255,.1)!important; border-color: rgba(255,255,255,.3); }
}

.home-documents .tab-content {
	.list-group { border-top: 1px solid rgba(255, 255, 255, 0.3); }
	.list-group-item { background: none; border: none; border-bottom: 1px solid rgba(255, 255, 255, 0.3); border-radius: none; }
	.list-group-item a { color: $brand-secondary; }
	.glyphicon { color: #fff; }
}
/* -- */