/* Default News Items */
.news-items { margin-bottom: 1em; }
.readmore { display: block; }

article {
	header { 
		margin: 0 0 20px; 
	.entry-title a { text-decoration: none;	}
	}
	.entry-meta {
		font-size: .8em;
		font-family: $brand-font2;
		color: $brand-tertiary;
		time { padding-left: .2em; padding-right: 1em; }
		.readtime { padding-right: 1em; }
		.entry-category { float: right; padding-left: 1em; }
		.author { display: none; }
		.glyphicon { font-size: .9em; padding-right: .2em; }
	}
	.news-item.single-post { margin-bottom: 3em; }
}

/* Single Post Page */
body.single-post {
	.single-header { 
		margin-top: 2em; 
		text-align: center;
		img { margin: 0 auto; max-width: 100%; height: auto; }
		.page-header { 	margin-top: 2em; }
	}
	#block { 
		padding-top: 0;	
		.entry-meta { text-align: center; }
		.entry-category { float: none; padding-left: 0; padding-right: 1em; }
		.entry-content hr { margin: 3% 0; }
	}
}
@media (min-width: $screen-lg-min) {
	body.single-post {
		#block .content { width: 80%; margin: 0 auto; }
	}
}
@media (max-width: $screen-xs-max) {
		body.single-post {
		.single-header { margin: 0; }
		.page-header { font-size: .75em; }
		#block {
			padding-top: 0;
		}
	}
}


/* News List */
article .news-item { 
 margin-bottom: 1.5em;
 color: $body-color;
	background: #fff;
 transition: $global-transition;
	box-shadow: 0 0 10px rgba(0,0,0,0);
	&:hover { box-shadow: 0 0 10px rgba(0,0,0,.15); }
	
 .news-thumb {
  img { height: auto; width: 100%; }
 }
 .news-header { }
	.entry-meta { 
		padding: 1em 1.75em;
		background: $brand-primary;
		font-family: $brand-font2;
		color: #fff;	
		.entry-category a { color: $brand-secondary; }
	}
	.entry-title {
		margin: 0;
		padding: 1em 1em 0;
		color: $brand-primary;
		font-size: 1.6em;
		font-family: $brand-font;
		a {
			color: $brand-primary;
			text-decoration: none;
			&:hover {
				color: $brand-secondary;
			}
		}
	}
	.news-body { }
	.news-caption {
		padding: .5em 1.5em .75em;
	   .btn {
		   position: absolute;
			bottom: 15px;
			right: 15px;
			margin: 0;
	   }
	}
	.readmore { display: inline-block; }
}

#news-dash article .news-item  {
}

body.search-results article {
	border-bottom: 1px solid rgba($brand-tertiary,.15);
	padding-bottom: .5em;
	margin-bottom: 1em;
	header {
		margin-bottom: .5em;
		.entry-title a { 
			color: $brand-secondary; 
			text-decoration: none;	
			&:hover { color: lighten($brand-secondary,15%); }
		}
	}
}
/* -- */


/* Featured News */
.featured-news {
	article .news-item {
		.news-thumb { 
			position: relative; overflow: hidden; 
			&:hover .news-thumbnail {
				-ms-transform: scale(1.05,1.05);
				-webkit-transform: scale(1.05,1.05);
				-moz-transform: scale(1.05,1.05);
				transform: scale(1.05,1.05);
			}
			.news-thumbnail { transition: $global-transition; }
		}
		.news-header-wrapper { 
			position: absolute;
			z-index: 1; 
			width: 100%; 
			height: 50%;
			bottom: 0;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#464646+0,464646+100&0+0,0.65+100 */
			background: -moz-linear-gradient(top,  rgba(70,70,70,0) 0%, rgba(70,70,70,0.65) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top,  rgba(70,70,70,0) 0%,rgba(70,70,70,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom,  rgba(70,70,70,0) 0%,rgba(70,70,70,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00464646', endColorstr='#a6464646',GradientType=0 ); /* IE6-9 */
		}
		.news-header { 
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 1.25em 1.5em;
		}
		.entry-title { 
			color: #fff; 
			font-size: 2.4em;
			padding: 0;
		}
		.news-body { }
		.news-caption {
			padding: 1.5em;
		}
	}
}

@media (max-width: $screen-xs-max) {
	article .news-item { margin-bottom: 1em; }
	.featured-news {
		article .news-item {
			.entry-title { font-size: 2em; }
		}
	}
}

body.page-template-template-home {
	article .news-item	.entry-meta .entry-category { display: none; } 
}
/* -- */


/* RSS Feed / Feedzy */
.feedzy-rss {
  li.rss_item { 
     padding-bottom: 5px !important;
    .title { font-size: 1.4em; }
    .rss_content small { color: #666 !important; }
  }
  li.rss_item:last-child { border-bottom: none !important; }
}
/* -- */