/* Home Carousel */
#homeCarousel {
	z-index: 1;
	margin: 0 auto;
	/* max-height: 75vh; */
	.carousel-inner { 
		overflow: visible; 
		img { width: 100%; }
	}
	a { text-decoration: none; }
 
	/* Overlay Caption */
	.overlay { 
		width: 100%;
		height: 100%;
  		position: absolute; 
		top: 40%;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.overlay .container { position: relative; }
 	.overlay-caption {
		position: absolute;
		right: 0;
		width: 40%;
		padding: 1.5em 1.5em 1em;
		background: rgba($brand-secondary,.8);
		color: #fff;
		text-align: center;
		h1, h2, h3, h4, h5, h6 { 
			color: #fff; 
			font-family: $brand-font;
			margin-top: 0;
		}
		h4 { font-size: 5em; }
		h5 { font-size: 2.4em; }
		h6 { font-size: 1.8em; }
		p { font-size: 1.2em; }
 	}
	
	/* Options */
	.item.bg-primary .overlay-caption { background-color: rgba($brand-primary,.8); }
	.item.bg-secondary .overlay-caption { background-color: rgba($brand-secondary,.8); }
	.item.bg-tertiary .overlay-caption { background-color: rgba($brand-tertiary,.8); }
	.item.bg-quaternary .overlay-caption { background-color: rgba($brand-quaternary,.8); }
	
	.item.box-bottom.bg-primary .overlay { background-color: $brand-primary; }
	.item.box-bottom.bg-secondary .overlay { background-color: $brand-secondary; }
	.item.box-bottom.bg-tertiary .overlay { background-color: $brand-tertiary; }
	.item.box-bottom.bg-quaternary .overlay { background-color: $brand-quaternary; }
	
	.item.gradient-bottom { 
		.overlay { top: 0; } 
		.container { position: static; } 
		.overlay-caption { background: none; width: 100%; bottom: 0; font-size: 1.6em; padding-bottom: 2em; }
	}
	.item.gradient-bottom.bg-primary .overlay { @include gradient-bottom-top($brand-primary,0,0%,$brand-primary,.65,100%); }
	.item.gradient-bottom.bg-secondary .overlay { @include gradient-bottom-top($brand-secondary,0,0%,$brand-secondary,.65,100%); }
	.item.gradient-bottom.bg-tertiary .overlay { @include gradient-bottom-top($brand-tertiary,0,0%,$brand-tertiary,.65,100%); }
	.item.gradient-bottom.bg-quaternary .overlay { @include gradient-bottom-top($brand-quaternary,0,0%,$brand-quaternary,.65,100%); }
	
	.item.box-right {
		.overlay-caption { right: 0; }
	}
	.item.box-left {
		.overlay-caption { left: 0; }
	}
	.item.box-center {
		.overlay-caption { position: relative; margin: 0 auto; }
	}
	.item.box-bottom {
		.overlay { position: relative; }
		.overlay-caption { position: relative; width: 100%; padding-bottom: 4em; }
	}
	.item.gradient-bottom {
		.overlay-caption { }
	}
	/* -- */
	
	/* Carousel Controls */
	.carousel-control { 
		opacity: 0; 
		transition: $global-transition; 
		width: 7%;
		span { padding: 0 35%; }
		&:hover { opacity: .75; } 
	}
	.carousel-control.left { left: 0; background: none; text-align: left; }
	.carousel-control.right { right: 0; background: none; text-align: right; }
	.carousel-control .previous, .carousel-control .next {
	  	top: 40%;
		font-size: 5.5em;
		position: relative;
	}
	.carousel-indicators { 
		margin: 0;
		width: 100%;
		bottom: 20px;
		left: 0;
	}
	/* -- */
	
	/* Video Icon */
	.video-icon {
		position: absolute;
		width: 100%;
		height: 100%;
		font-size: 15vw;
		color: rgba(#fff,.4);
		> div {
			display: table;
			width: 100%;
			height: 100%;
		}
		span { 
			display: table-cell;
			text-align: center;
			margin: 0 auto;
			vertical-align: middle;
		}
	}
	/* -- */
	
}
.home.carousel-container { 
	position: relative;
	overflow: hidden;
}

@media (min-width: $screen-xl-min) {
	#homeCarousel .overlay {
		top: 50%;
	}
}

@media (max-width: $screen-sm-max) {
	#homeCarousel { 
		max-height: auto; 
		.overlay { 
			background: $brand-secondary;
			position: relative; 
			top: 0; 
		}
		.overlay-caption {
			background: none !important;
			position: relative;
			width: 100%;
			padding-bottom: 3.5em !important;
			font-size: 1em !important;
		}
		h4 { font-size: 4em; }
		h5 { font-size: 3em; }
		h6 { font-size: 2em; }
	}
	
	.item.bg-primary .overlay { background-color: $brand-primary !important; }
	.item.bg-secondary .overlay { background-color: $brand-secondary !important; }
	.item.bg-tertiary .overlay { background-color: $brand-tertiary !important; }
	.item.bg-quaternary .overlay { background-color: $brand-quaternary !important; }	
	
	.home.carousel-container { overflow: visible; }
	.carousel-control { display: none; }
}
/* -- */


/* CAROUSEL FADE EFFECT ANIMATION */ 
.carousel-fade {
	.carousel-inner {
	.item {
		transition-property: opacity;
	}
	.item,
	.active.left,
	.active.right {
		opacity: 0;
	}
	.active,
	.next.left,
	.prev.right {
		opacity: 1;
	}
	.next,
	.prev,
	.active.left,
	.active.right {
		left: 0;
		transform: translate3d(0, 0, 0);
  	}
 	}
	.carousel-control {
		z-index: 2;
 	}
}
/* -- */