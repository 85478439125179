.newsletter-widget {
  li { 
    list-style-type: none; 
    padding: 0; 
    margin: 0 0 .7em; 
    transition: $global-transition;
    &:hover .fa { 
					background: $brand-primary; 
				}
  }
  .fa { 
			font-size: 1.4em; 
			padding: 15px;
			color: #fff;
			width: 15%;
			text-align: center;
			background: $brand-tertiary; 
		}
  .newsletter { 
			width: 85%; 
			float: right; 
			padding: 5px 15px; 
			color: $body-color; 
			font-size: .9em; 
			> div { font-weight: bold; color: $brand-primary; }
		}
}