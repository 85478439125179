.main { padding-left: 0; padding-right: 0; }
body.sidebar-primary .wrap.sidebarleft .main, body.sidebar-primary .wrap.sidebarright .main { padding-left: 15px; padding-right: 15px; }
body.sidebar-primary .wrap.sidebarleft .main { float: right; }
body.sidebar-primary .wrap.sidebarleft .sidebar { margin-right: 0; padding-right: ($grid-gutter-width / 2); }

.sidebar {
  float: right; 
  margin-right: -15px;
  padding-right: 0;
  font-size: 1em;
  .widget {
			padding: 1.5em;
   color: #494949;
   background: #fff;
   margin-bottom: 1.5em; 
   /* box-shadow: 0 0 10px rgba(0,0,0,.15); */
    
   h1, h2, h3, h4 { font-family: $brand-font; font-weight: 200; }
   h4 {
				margin: 0;
    font-size: 1.4em;
    color: $brand-tertiary;
				border-bottom: 5px solid rgba($brand-tertiary,.1);
				padding-bottom: .5em;
    a { color: $brand-secondary; &:hover { text-decoration: none; } }
   }
    
   ul { 
    margin: 1em 0 0; 
    padding: 0;
    li { 
					padding: 0.25em 0 0.25em 1.3em;
					display: list-item; 
					list-style-type: none; 
					font-size: 1em;
				}
				li:last-child { border-bottom: none; }
   }
   ul li {
				a { 	
					position: relative; 
					&:before { 
						font-size: .8em; 
						content: "\f105"; /* fa-angle-right */
						font-family: 'FontAwesome'; 
						color: rgba($brand-tertiary,.4); 
						position: absolute; 
						top: .2em; 
						left: -1.2em; 
					}
				}
			}
			ul.children {
				margin: 0.25em 0 0;
				padding: 0;
			}
			
			ul.list-style-none { padding-left: .2em; li { list-style-type: none; } }
    
   a { color: $brand-primary !important; text-decoration: none; padding: 0; }
   a:visited { color: lighten($brand-primary,7%); }
   a:hover, a:focus { color: $brand-secondary !important; text-decoration: none; background: none; }
  }
		
		.widget.documents ul {
			padding: 0 10px 25px;
			li a { 	
				position: relative;
				&:before { 
				 font-size: .8em; 
					content: "\f0f6"; /* fa-file-text-o */ 
					font-family: 'FontAwesome'; 
					color: rgba($brand-tertiary,.4); 
					position: absolute; 
					top: .2em; 
					left: -1.5em; 
				}
			}
		}
		
		.search-form {
			position: relative;
			> label, input.search-field { width: 100%; height: 40px; }
			.search-submit { margin: 0; padding-top: 7px; padding-bottom: 6px; position: absolute; right: 0; top: 0; }
		}
		
}


@media (max-width: $screen-sm-max) {
 body.sidebar-primary .wrap.sidebarleft .main, body.sidebar-primary .wrap.sidebarleft .sidebar,
	body.sidebar-primary .wrap.sidebarright .main, body.sidebar-primary .wrap.sidebarright .sidebar,
	.main, .sidebar,
	.sidebar-primary .main {
  float: none;
  width: 100%;
  margin-left: 0;
		margin-right: 0;
  padding-left: 15px;
		padding-right: 15px;
 }
	body.page-template-template-home #body-wrap .wrap .main,
	body.page-template-template-full-width #body-wrap .wrap .main, {
		padding-left: 0;
		padding-right: 0;
	}
}