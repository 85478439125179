.gform_wrapper {
		label.gfield_label {
			font-family: $brand-font2; 
		}
		.description, 
		.gfield_description, 
		.gsection_description, 
		.instruction {
			font-size: .75em;
			letter-spacing: normal;
		}
		.field_sublabel_above .ginput_complex.ginput_container label, 
		.field_sublabel_above div.ginput_container[class*="gfield_date_"] label, 
		.field_sublabel_above div.ginput_container[class*="gfield_time_"] label, 
		.field_sublabel_below .ginput_complex.ginput_container label, 
		.field_sublabel_below div.ginput_container[class*="gfield_date_"] label, 
		.field_sublabel_below div.ginput_container[class*="gfield_time_"] label {
			font-size: .75em;
			letter-spacing: normal;
			font-weight: normal;
			font-family: $body-font;
		}
		input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]) {
			padding: .3em .5em;
		}
}