/* CTA */
.cta { 
	position: relative;
	.cta-content { 
		z-index: 2;
		position: relative;
		text-align: center;
		color: #fff;
	}
	h1,h2,h3,h4,h5,h6 {
		color: #fff;
	}
	h1 { font-size: 2.6em; }
	h2 { font-size: 2.3em; }
	h3 { font-size: 2em; }
	.cta-caption {
		padding: 3em 4em;
	}
	
	.cta1 { background: $brand-tertiary; }
	.cta2 { background: #5a5a5a; }
	.cta1.cta-half { width: 50%; left: 0; }
	.cta2.cta-half { width: 50%; right: 0; }
	
	.cta-image {
		position: absolute;
		top: 0; 
		height: 100%; 
		width: 100%;
		content: ""; 
		z-index: 1;	
		&:before {
			position: absolute;
			z-index: -1;
			height: 100%;
			width: 100%;
			content: "";
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 50% 50%;
			opacity: .1;
		}
	}
	
}

@media (max-width: $screen-sm-max) {
	.cta {
		.cta1.cta-half, .cta2.cta-half { width: 100%; }
		.cta2.cta-half { display: none; }
		.cta-content {
			> div:first-child { padding-bottom: 0; }
			.cta-caption { padding: 2em 0; }
		}
	}
}
/* -- */


/* Rich Footer */
.base-column-content {
 background: $brand-secondary;
 color: #fff;
 padding: 1em 0 15%;
 h4 {
  margin-top: 1em;
  margin-bottom: 1em;
  color: #fff; 
  font-size: 2.2em;
 } 
}
/* -- */


.feature-block {
 padding: 3% 0;
 color: #fff;
 background: $brand-secondary;
 border-top: 10px solid darken($brand-tertiary, 5%);
 h1, h2, h3, h4, h5, h6 { color: #fff; }
}



/* Footer */
footer#footer {
	border-top: 10px solid $brand-secondary;
	background: $brand-primary;
	color: #fff;
	position: relative;
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: .07;
		background: url('../images/sunbury-logo_transparent-white.png') no-repeat 5% -15vw;
		content: "";
		background-size: 50vw;
	}
 .col { margin-bottom: 2em; }
 img { max-width: 100%; }
 h1, h2, h3, h4, h5, h6 { font-family: $brand-font; color: #fff; }
 h4 { font-size: 1.8em; }
	.logo { width: 70%; margin: 1em auto; }
 .footer-upper {
		font-size: .9em;
  .container {
   padding-top: 2em;
   padding-bottom: 1.8em;
  }
  .menu li {
   list-style-type: none;
   li { list-style-type: none; color: #fff; }
   a { color: #fff; }
  }
  .quick-links-nav > a { display: none; }
 }
 .footer-lower {
  color: rgba(255,255,255,.5);
  padding: .8em 0;
 }
 .site-copyright, .site-credit {
  font-size: .8em;
  a { color: rgba(255,255,255,.5); }
 }
 .site-credit { text-align: right; }
 a { color: #fff; text-decoration: none; &:hover { text-decoration: underline; } }
 ul {
  padding-left: 0;
  list-style: none;
  li { list-style-position: inside; }
  li.disable { display: none; }
 }
	.map-embed { display: none; }
}

@media (max-width: $screen-sm-max) {
 footer#footer .site-credit { padding-top: 1em; text-align: left; }
	footer#footer:before {
		background-position: 5% -45vw;
		background-size: 150vw auto;
	}
}
/* -- */