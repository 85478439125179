/* DEFAULT PAGES */
#frame { background: $body-background; }
#pushobj {
	margin: 0 auto;
	/*max-width: 2300px;*/
	position: relative;
}
#body-wrap { 
	position: relative; 
	z-index: 1; 
	background: $body-background;
	top: 7em;
}

/* 
#block { 
 padding-left: 30px; 
	padding-right: 30px;
}
*/
.content-wrap, .block-wrap { padding-top: 3em; padding-bottom: 3em; }
.content-wrap .main { margin-bottom: 3em; }

.white-block { 
	background: #fff; 
	padding: 1em 0; 
	box-shadow: 0 0 8px rgba(0,0,0,.15);
}

/* SubImage*/
$subimage_lg: 360px; /* Large Sub-Image Height */
$subimage_sm: 240px; /* Small Sub-Image Height */ 

#subimage { 
	margin-top: 2em;
	position: relative;
	height: $subimage_lg;
	text-align: left;
	background: rgba(70,70,70,0.65);
	overflow: hidden;
	.subimage { 
		height: $subimage_lg;
		background-size: cover;
		background-position: 50% 50%;
		box-shadow: 0 -50px 50px rgba(0,0,0,.15) inset;
		img { width: 100%; }
 }
	
	.subimage-header {
		position: absolute;
		bottom: 0;		
		z-index: 1;
		width: 100%;
		height: 50%;
		text-align: center;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#464646+0,464646+100&0+0,0.65+100 */
		background: -moz-linear-gradient(top,  rgba(70,70,70,0) 0%, rgba(70,70,70,0.65) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(70,70,70,0) 0%,rgba(70,70,70,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(70,70,70,0) 0%,rgba(70,70,70,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00464646', endColorstr='#a6464646',GradientType=0 ); /* IE6-9 */
		> div {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
		h1 { 
			margin: 0;
			padding-bottom: 1.5em;
			color: #fff; 
		}
	}
	
	.breadcrumb-bar {
		position: absolute;
		top: 0;
	}
}

@media (min-width: $screen-md-min) {
	#subimage .subimage {
		@include image-animated;
	}
}

.bg-clip, .white-clip { 
	position: absolute;
	bottom: 0;
	z-index: 2;
	width: 100%;
	height: 67px;
}
.bg-clip { background: url('../images/bg-clip.png') no-repeat 50% 0; }
.white-clip { background: url('../images/white-clip.png') no-repeat 50% 0; }


@media (max-width: $screen-sm-max) {
	body.page-template-template-home,
	body.page-template-template-full-width {
		.main, .main.sidebarleft, 
		.main.sidebarright, .sidebar, 
		.sidebar-primary .main, 
		.sidebar.sidebarleft, 
		.sidebar.sidebarright { 
			padding-left: 0;
			padding-right: 0;
		}
	}
	#block, body.home #block { 
		padding-left: 15px; 
		padding-right: 15px; 
		padding-top: 1.5em; 
	}
	#subimage {
		height: $subimage_sm; 
		.subimage { max-height: $subimage_sm; }
		.subimage-header { font-size: .8em; }
	}
}

@media (max-width: $screen-xs-max) {
	#subimage { margin: 0; }	
}

/* Breadcrumbs */
.breadcrumb-bar {
	width: 100%;
	padding: .2em 0 .3em;
	background: rgba($brand-primary,.9);
	font-size: 1.1em;
	font-family: $brand-font2;
	.breadcrumb, .breadcrumb a, .breadcrumb a:visited {
		color: rgba(255,255,255,.75);
	}
	.breadcrumb a:hover { color: #fff; }
}
.breadcrumb {
 background: none;
 padding: 0; 
 margin: 0;
 color: #a2a2a2;
 font-size: .8em;
 a, a:visited { color: #a2a2a2; }
}
.page-header { 
 margin: .3em 0 0; 
 padding: 0;
 border: none; 
 color: $brand-tertiary; 
 h1, h2 { margin: 0 0 .5em; font-size: 3.2em; color: $brand-primary; }
}
/* -- */


/* Feature Children */
#feature-children {
	margin-top: 1.5em;
	h4 {
		margin: 0 0 1em;
		font-family: $brand-font; 
		font-style: italic;
		text-align: center; 
		color: $brand-primary;
	}
	.well {
		border-radius: 0;
		border: none;
		border-top: 6px solid $brand-primary;
		background: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0);
		margin-bottom: 2em;
		padding: 12px 19px;
		transition: $global-transition;
		.entry-title { 
		 font-family: $brand-font2;
			font-size: 1.6em;
			font-weight: bold; 
			a { text-decoration: none; color: $brand-primary; }
		}
	}
	.well:hover {
		box-shadow: 0 0 10px rgba(0,0,0,.15);
	}
}
/* -- */


/* Documents */
.documents {
	h4 {
		margin: 0 0 1em;
		font-family: $brand-font; 
		font-style: italic;
		text-align: center; 
		color: $brand-primary;
	}
	a { text-decoration: none !important; }
	.document-card {
		position: relative;
		margin:  .5em 0 1em;
		padding: 15px 17px;
		min-height: 6.5em;
		font-family: $brand-font2;
		background: #fff;
		box-shadow: 0 0 10px rgba(0,0,0,0);
		transition: $global-transition;
		&:hover {
			box-shadow: 0 0 10px rgba(0,0,0,.15);
			margin: 0 0 1.5em;
		}
		&:before {  
			position: absolute; 
			top: -2px; 
			right: 15px;
			content: "\f105"; /* fa-angle-right */
			font-family: 'FontAwesome'; 
			color: rgba($brand-tertiary,.2); 
			font-size: 5em;
			line-height: 1em; 
			transition: $global-transition;
		}
		span { display: block; }
		span.link { 
			width: 80%;
			color: $brand-primary;
			font-size: 1.2em;
			line-height: 1.2em;
			font-weight: bold;
			@include anti-alias;
		 a { color: $brand-primary; text-decoration: none; }
		}
		span.meta {
			font-size: .9em;
			padding-top: .3em;
			color: $body-color;
		}
		
	}
}
@media (max-width: $screen-sm-max) {
	.documents .document-card { min-height: auto; }
}
/* -- */


/* Feature Grid */
.feature-grid {
	position: relative;
	.item > a:hover > .feature-item {
		box-shadow: 0 0 6px rgba(0,0,0,.2);
	}
	.feature-item { 
		position: relative; 
		background: $brand-tertiary;
		text-align: right;
		transition: $global-transition;
	}
	.feature-header {
		background: $brand-secondary;
		color: #fff;
		font-family: $brand-font;
		font-size: 1em;
		text-align: center;
		padding: .5em .8em;
		position: absolute;
		width: 100%;
		bottom: 0;
		h4 { 
			color: #fff;
			margin: 0;
		}
	}
	.feature-grid-scroll-control {
		position: absolute;
		height: 4em;
		width: 100%;
		top: 35%;
		.control { font-size: 4em; color: rgba(0,0,0,.1); }
		.control:hover { color: rgba(0,0,0,.2); }
		#feature-grid-prev {
			position: absolute;
			left: -3%;
		}
		#feature-grid-next {
			position: absolute;
			right: -3%;
		}
	}
	#feature-grid-pager {
		margin-top: 1em;
		text-align: center;
		a {
			background: rgba($brand-tertiary,.15);
			border: 1px solid rgba($brand-tertiary,.2);
			box-shadow: 1px 1px 0 #fff, inset 1px 1px 0 rgba(0,0,0,.1);
			width: .75em;
			height: .75em;
			border-radius: 10em;
			margin: 0 .5em 0 0;
			display: inline-block;
			&:hover { background: rgba($brand-secondary,.3); }
			span {
				display: none;
			}
		}
		a.selected {
			background: rgba($brand-tertiary,.3);
			cursor: default;
		}
	}
}
@media (max-width: $screen-sm-max) {
	.feature-grid .feature-header { font-size: .9em; }
	.feature-grid-scroll-control { display: none; }
}
/* -- */


/* Portal Btns */
.portal-btns {
	position: relative;
	padding: 0em 0 3em;
	background: $brand-primary url('../images/bg-red.jpg') repeat 0 0;
	box-shadow: 0 -50px 50px rgba(0,0,0,.15) inset;
	.portal-btn {
		font-family: $brand-font2;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		@include anti-alias;
		a {
			padding: 1.5em;
			color: #fff;
			display: block;
			transition: $global-transition;
			span.icon {
				display: block;
				font-size: 3em;
				line-height: 1.6em;
			}
			span { 
				display: block;
				text-align: center; 
				line-height: 1.1em;
			}
			&:hover {
				color: $brand-secondary;
				padding: 1em 1.5em 2em 1.5em;
			}
		}
	}
}
@media (max-width: $screen-sm-max) {
	.portal-btns .portal-btn .title {
		font-size: 1.3em;
	}
}
/* -- */


/* HOME PAGE */
#news-dash { margin: 3em 0; }
.news-block {
	h3, h4 { color: $brand-primary; margin-bottom: .7em; a { color: $brand-primary; } }
	h4 { font-size: 22px; }
	.calendar-widget, .newsletter-widget { margin: 1em 0; }
	.ai1ec-agenda-widget-view { margin-bottom: 2em; }
	.newsletter-widget {
		ul.list-group .list-group-item:nth-of-type(2n+1) { background: rgba( $brand-primary, .05 ); }
	}
}
.social-block {
	.social-icons {
		float: right;
		font-size: 1.6em;
		margin-left: .8em;
	}
}
/* -- */


/* CONTACT PAGE */
body.page-template-template-contact .main .campus-info { width: 100%; float: none; }
/* -- */


/* Subscription Block */
.subscriber-block {
	margin-bottom: 3em;
	background: $brand-tertiary;
	color: #fff;
	position: relative;
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: .07;
		background: url('../images/sunbury-logo_transparent-white.png') no-repeat 130% -25vw;
		background-size: 40vw auto;
		content: "";
	}
	.wysija-paragraph { 
		display: inline-block; 
		float: left; 
		padding-right: 6px; 
	}
	.wysija-submit { 
		float: left; 
	}
}
.subscriptions {
	padding: 1.5em 2em;
	h4 { 
		margin-top: 1em;
		color: #fff; 
		font-family: $brand-font; 
		font-size: 1.8em; 
	}
	.description { 
		color: #fff;
		display: clear; 
		margin-bottom: .5em; 
	}
	.wysija-paragraph { 
		margin-bottom: .5em;
	}
	input[type="text"] { 
		font-size: 1em; 
		color: #bdbdbd; 
		padding: .4em .8em; 
		border: none;
		border-radius: .3em;
		box-shadow: none;
		height: auto; 
	}
	.wysija-submit { 
		margin: 0; 
		font-size: 1.1em; 
		padding: .2em .6em; 
		border: 2px solid #fff; 
		&:hover {
			background: #fff;
			border-color: #fff;
			color: $brand-tertiary;
		}
	}
}


/* Feature Card */
.feature-card {
	background: #fff;
	box-shadow: 0 0 6px rgba(0,0,0,.2);
	position: relative;
	overflow: hidden;
	margin-bottom: 1em;
	&:after {
		width: 100%;
		height: 100%;
		position: absolute;
		bottom: 0;
		right: -30px;
		content: "";
		background: url('../images/cltc-flame.png') no-repeat 100% 100%;
		opacity: .1;
	}
	> * { position: relative; z-index: 1; }
	.image { 
		width: 45%;
		float: left;
		background-color: $brand-tertiary;
	}
	.caption {
		width: 55%;
		float: left;
		padding: 1em 1.5em;
	}
}
/* -- */


/* Feature Block */
.feature-block {
 background: lighten($brand-tertiary,15%);
	position: relative;
	z-index: -1;
	&:before {
		content: "";
		background: url('../images/bg-pattern.png') repeat;
		opacity: .15;
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: -1;
	}
	.container {
		padding-top: 2em;
		padding-bottom: 2em;
	}
}
/* -- */


/* Misc */
.mid-feature {
	padding: 3em 0;
}

/* Tabs */
.tab-content {
	padding: 1.5em 0;
}
#documentsContent { 
	padding: 0; 
}