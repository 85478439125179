$admin-bar: 2em;
$brand-width: 129px;

.navbar-fixed-bottom, .navbar-fixed-top { position: fixed !important; }
body.admin-bar #header.sticky.navbar-fixed-top { top: $admin-bar; }

header#header {
	position: absolute;
	z-index: 2;
	background: #fff;
	box-shadow: 0 0 8px rgba(0,0,0,.1);
	width: 100%;
	.row { position: relative; }
	
	.brand {
		padding: 10px 0;
		max-width: $brand-width;
		img { 	width: 100%;	}
	}
	.menu-logo {
		float: left;
		padding: 11px 16px;
		width: 140px;
		display: none;
	}
	
	/* Top Head */
	.extras-col {
		float: right; 
	}
	.top-head {
		float: right; 
		.head-phone { 
			float: left;
			display: inline-block;
			font-size: 20px;
			line-height: 20px;
			text-align: right;
			color: $brand-tertiary;
			padding: 16px;
			margin-right: .5em;
			.fa { color: $brand-primary; }
		}
		.btn { 
			display: inline-block; 
			float: left; 
			margin: 0;	
			font-size: 18px;
			line-height: 16px;
			padding: 16px 14px;
			height: 50px;
			.fa { font-size: 18px; }
		}
		#menu-quick-links {
			float: left;
			display: inline-block;
			> li > a { 
				text-transform: uppercase; 
				font-weight: 600;
				font-size: 18px;
				line-height: 20px;
				padding: 16px 14px 15px;
				height: 50px;
				@include anti-alias;
			}
			.caret { color: rgba(255,255,255,.5); }
		}
	.btn-search { width: 50px; }
	.social {
			float: left;
			a {
				float: left;
				display: inline-block;
				padding: 14px 16px;	
				font-size: 24px;
				line-height: 24px;	
				height: 50px;
				width: 50px;
				color: #fff;
				text-align: center;
				background: #b5b2b3;
				text-decoration: none;
			}
		}
	}
 	
	/* Search */
	#searchFormContainer {
		margin-top: .75em;
		width: 320px;
		display: none;
		.searchform {
			position: relative;        
		}
		.searchform input[type="text"] {
			float: left;
			font-size: 1.1em;
			height: 2.2em;
			color: $brand-tertiary;
			border-radius: .5em;
			border: 2px solid rgba($brand-tertiary,.1);
			box-shadow: none;
  		}
		.searchform .btn {
			color: rgba($brand-tertiary,.5);
			font-size: 1.1em;
			background: none;
			border: none;
			display: block;
			margin: 0;
			padding: .35em .5em;
			position: absolute;
			right: 0;
			.fa { padding-right: 3px; }
			span { display: none; }
		}
		.searchform .btn:hover { color: $brand-tertiary; }
	}
	
	
	/* Navigation */
	nav.navigation {
		position: absolute;
		bottom: 0;
		width: 100%;
		.navbar-toggle { 
			.float-left { padding-top: 5px; }
			.icon-bar { background: $brand-primary; }
			.menu-toggle { padding-left: 10px; }
		}
		.navbar-toggle:hover { background: $brand-primary; color: #fff; }
	}
	
}

@media only screen and (min-width: $screen-xl-min) {
	.navbar-nav > li > a {
		font-size: 1em !important;
	}
}
@media only screen and (max-width: $screen-md-max) and (min-width: $screen-md-min) {
	.navbar-nav > li > a {
		font-size: .9em !important;
	}
	#menu-quick-links {
		display: none;
	}
}
@media only screen and (max-width: $screen-md-max) {
	header#header {
		.brand { width: $brand-width/1.25; padding: 10px; }
		nav.navigation { padding-top: .5em; }
	}
}
@media only screen and (max-width: $screen-sm-max) {
	body.admin-bar #header { top: 0; }
	header#header {
		/*
		position: relative; 
		top: 0;
		*/
	}
	header#header .container, #subimage .container {
		width: 100%;
	}
	.home.carousel-container { margin-top: 0; }
}
@media only screen and (max-width: $screen-xs-max) {
	header#header {
		.top-head .head-phone { padding: 0; float: none; font-size: 1em; }
		.top-head #searchFormContainer { display: none; }
	}
}


/* NAVIGATION */
header#header .navbar-primary-container { 
	font-family: $brand-font2;
	font-weight: 200;
	color: $brand-primary;
}
.navbar-nav {
  
	.fa { display: none; }
	.caret {
		color: rgba($brand-primary,.5);
		border: none;
		height: auto;
		width: .8em;
		vertical-align: top;
		&:before {
			content: "\f107"; /* fa-angle-down */
			font-family: 'FontAwesome';
		}
	} 
	> li > a {
    	color: $brand-primary;
    	font-size: 1.1em;
		line-height: 1em;
    	padding: .95em 1em;
		text-align: left;
		font-family: $brand-font2;
		font-weight: 200;
		text-decoration: none;
		&:focus, &:hover { 
			color: #fff; 
			background: $brand-primary; 
			.caret { color: rgba(255,255,255,.5); }
		}
	}
	> li.current-menu-parent > a { 
		background: $brand-secondary; 
		color: #fff; 
	}
	> li > .dropdown-menu { 
		border: none;
		font-size: 1em;
		padding: .6em 0;
		background: $brand-primary;
		border-radius: 0;
	}
	.dropdown-menu > li > a {
		color: #fff;
		font-weight: 200;
		padding: .4em 1em;
		text-decoration: none;
	}
	.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover, .dropdown-menu a:hover, .dropdown-menu a:focus { 
		color: #fff !important; 
		background: $brand-secondary !important; 
	}
	.quick-links-nav {
		> a { 
			color: #fff;
    		background: $brand-primary !important;
			cursor: default;
			&:hover { 
				color: #fff;
				background: $brand-tertiary !important;
			}
    		.caret { }
		}
		.dropdown-menu { right: 0; left: auto; }
	}
}
.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
	border: none;
	color: #fff;
	background: $brand-secondary;
} 
.navbar-right .dropdown-menu { left: 0; right: auto; }

/* Hover DropDown Menus for Desktop */
@media only screen and (min-width: $screen-sm-min) {
	.navbar-nav .dropdown:hover .dropdown-menu {
		display: block;
	}
	.navbar-nav .dropdown-submenu {
		position: relative !important;
	}
	.navbar-nav .dropdown-submenu > .dropdown-menu {
		top: 0 !important;
		left: 100% !important;
		margin-top: -6px !important;
		margin-left: -1px !important;
		border-radius: 0 !important;
	}
	.navbar-nav .dropdown-submenu:hover > .dropdown-menu {
		display: block !important;
	}
	.navbar-nav .dropdown-submen > a:after {
		display: block;
		content: "\f105";
		font-family: 'FontAwesome';
		margin-top: -18px;
		right: 15px;
		position: absolute;
		font-weight: 300;
	}
}


@media only screen and (max-width: $screen-sm-max) {
	#click-menu, #responsive-menu, #mlpm_btn,
	nav .navbar-primary-container.collapse.in { display: block !important; }
	nav .navbar-primary-container.collapse { display: none !important; }
}

#menu #quick_nav { display: block !important; }


/* Responsive Menu Plugin */
#click-menu,
#click-menu.admin-bar-showing ~ .navbar-toggle,
#click-menu2 ~ .navbar-toggle,
#mlpm_btn ~ .navbar-toggle { display: none !important; }


html.touchevents { 
	#click-menu, #responsive-menu, #mlpm_btn { display: block !important; }
	nav .navbar-primary-container.collapse { display: none !important; }
}

#click-menu, #click-menu2, #mlpm_btn, .mlpm_btn {
	margin: 40px 0 0 !important;
	padding: 10px 14px;
	border-radius: 0;
	border-radius: none;
	border: none;
	background: none;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 22px;
	line-height: 22px;
	color: $brand-primary !important;
	font-family: $brand-font2;
	@include anti-alias;
	&:hover { border: none; color: #fff !important; background: $brand-primary !important; text-decoration: none; }
}
#mlpm_btn .glyphicon-menu-hamburger { font-size: .85em; }
  
#RM3Lines { display: inline-block; }
#click-menu-label { display: inline-block; font-weight: bold; padding-left: 6px; }

#responsive-menu {
	top: 0 !important; left: 0 !important; 
	z-index: 2000;
	#responsive-menu-title, ul#menu-head > li { font-weight: bold; }
	#responsiveSearch { height: auto !important; }
	ul.sub-menu { padding-left: 0; } 
	ul.sub-menu li { font-weight: normal; }
}

.pageoverlay:before {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
	background: rgba(0,0,0,0);
	z-index: -1;
}
/* Disabled due to iOS Touch/Swipe bug exits */
#pushobj.opened .pageoverlay:before {
	background: rgba(0,0,0,.85);
	z-index: 100;
}

#pushobj.opened #header nav.navigation,
#pushobj.opened #click-menu, 
#pushobj.opened #click-menu2, 
#pushobj.opened #mlpm_btn { z-index: -1; }
.mlpm_w h2 { 
	position: relative;
	background: rgba(0,0,0,.2);
	padding: 0; 
	img { width: 100px; padding: .6em; }
	span { padding: .6em; }
	&:before {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: .07;
		background: url('../images/sunbury-logo_transparent-white.png') no-repeat -203px -270px;
		content: "";
		background-size: 500px;
	}
}
.mlpm_w ul li a {
	font-family: $brand-font2;
	font-weight: normal;
}